import { MatxLoadable } from "matx";
import { authRoles } from "../../auth/authRoles";

const Notifications = MatxLoadable({
    loader: () =>
        import ("./Notifications")
})



const NotificationRoutes = [{
        path: "/notifications",
        component: Notifications,
        auth: authRoles.all
    },

];

export default NotificationRoutes;